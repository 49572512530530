<template>
  <ModalToolbar :id="id" max-width="mw-800px">
    <template v-slot:header>
      <div class="modal-header" id="kt_modal_create_api_key_header">
        <h2>Update Fund</h2>
      </div>
    </template>
    <template v-slot:body>
      <div class="modal-body scroll-y py-0 mt-8">
        <Form class="form" ref="fundForm">
          <!--begin::Input group-->
          <div class="mb-5 fv-row">
            <label class="fs-5 fw-bold mb-2">Wishlist</label>
            <Field
              v-model="currentWishlist"
              name="currentWishlist"
              class="form-select form-select-solid"
              data-control="select2"
              data-hide-search="true"
              data-placeholder="Select a Wishlist"
              as="select"
            >
              <option value="">Select a Wishlist....</option>
              <template v-for="(wishlist, i) in wishlists" :key="i">
                <option
                  :label="wishlist.name"
                  :value="wishlist._id"
                  v-if="wishlist.fundSlot === wishlistType"
                  :selected="wishlist._id === currentWishlist"
                >
                  {{ wishlist.name }}
                </option>
              </template>
            </Field>
          </div>
        </Form>
      </div>
    </template>
    <template v-slot:footer>
      <div class="modal-footer flex-end bg-light-grey">
        <button
          type="submit"
          class="btn me-3 btn-primary"
          ref="updateFundRef"
          @click="updateFundDetail"
        >
          <span class="indicator-label"> Update </span>
          <span class="indicator-progress">
            Please wait...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
        <button
          ref="closeFundModal"
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </template>
  </ModalToolbar>
</template>
<script>
import { computed, ref, watch } from "vue";
import ModalToolbar from "@/common/components/ModalToolbar.vue";
import { Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
import { useRoute } from "vue-router";
import { WishlistFundEnum } from "@/common/enums/wishlistFundEnum";
import { updateFund } from "@/api/mongoFund";

export default {
  name: "UpdateFundModal",
  components: { ModalToolbar, Field, Form },
  props: {
    wishlistID: {
      type: String,
      required: true,
    },
    wishlistType: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const loading = ref(false);
    const store = useStore();
    const route = useRoute();
    const fundForm = ref(null);
    const status = ref(false);
    const updateFundRef = ref(null);
    const closeFundModal = ref(null);
    const currentWishlist = ref("");
    const wishlists = computed(() => {
      return store.getters["WishlistModule/getWishlists"];
    });

    const updateFundDetail = async () => {
      try {
        //Disable button
        updateFundRef.value.disabled = true;
        // Activate indicator
        updateFundRef.value.setAttribute("data-kt-indicator", "on");

        const payload = {
          fundID: route.params.fundID,
        };
        const selectedWishlist = wishlists.value.find(
          (obj) => obj._id === currentWishlist.value
        );
        if (props.wishlistType === WishlistFundEnum.ACQUIRED_CATALOGUE_LIST) {
          if (payload.equityCatalogues) {
            delete payload.equityCatalogues;
          }
          payload["acquiredCatalogues"] = selectedWishlist;
        } else {
          if (payload.equityCatalogues) {
            delete payload.equityCatalogues;
          }
          payload["equityCatalogues"] = selectedWishlist;
        }

        const { data } = await updateFund(payload);
        await store.commit("FundsModule/SET_FUND_DETAIL", data);
        closeFundModal.value.click();
        ElNotification({
          title: "Success",
          message: "Fund updated successfully",
          type: "success",
        });
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: e.response.data.message[0],
          type: "error",
        });
      } finally {
        updateFundRef.value.removeAttribute("data-kt-indicator");
        updateFundRef.value.disabled = false;
      }
    };

    watch(
      () => props.wishlistID,
      (value) => {
        if (value) {
          currentWishlist.value = value;
        }
      },
      { immediate: true }
    );
    // watch(
    //   () => wishlists.value,
    //   (value) => {
    //     if (value) {
    //       currentWishlist.value = value.find(
    //         (obj) => obj._id === props.wishlistID
    //       );
    //     }
    //   },
    //   { immediate: true }
    // );

    return {
      loading,
      fundForm,
      status,
      currentWishlist,
      wishlists,
      closeFundModal,
      updateFundRef,
      updateFundDetail,
    };
  },
};
</script>
