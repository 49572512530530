<template>
  <div class="wishlist-ui-v2 mb-8" v-loading.fullscreen.lock="loading">
    <FundSidebar
      v-if="!loading"
      :fund-name="fundDetail.name ? fundDetail.name : ''"
      :quick-facts="{
        totalTracksInEquity: fundDetail.totalTracksInEquity
          ? fundDetail.totalTracksInEquity
          : 0,
        totalTracksInAcquired: fundDetail.totalTracksInAcquired
          ? fundDetail.totalTracksInAcquired
          : 0,
      }"
    />
    <div class="fund-detail-v2 row" v-if="!loading">
      <FundTable
        title="Equity Catalogue Table"
        :type="WishlistFundEnum.EQUITY_CATALOGUE_LIST"
        :fund="
          fundDetail && fundDetail.tableDataForEquity
            ? fundDetail.tableDataForEquity
            : {}
        "
      />
      <FundTable
        title="Acquired Catalogue Table"
        :type="WishlistFundEnum.ACQUIRED_CATALOGUE_LIST"
        :fund="
          fundDetail && fundDetail.tableDataForAcquired
            ? fundDetail.tableDataForAcquired
            : {}
        "
      />
      <div>
        <el-row :gutter="12">
          <el-col
            class="margin-bottom-12"
            :lg="6"
            :md="12"
            :sm="12"
            :xs="24"
            v-for="(revenue, key, index) in sumOfRevenues"
            :key="index"
          >
            <kpi-card-v2
              :title="formatNumberIntoHumanizeForm(revenue, 0)"
              :subText="capitalizeEveryWordFirstLetter(key.replace(/_/gi, ' '))"
            />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import FundSidebar from "@/funds/components/FundSidebar.vue";
import FundTable from "@/funds/components/FundTable.vue";
import { WishlistFundEnum } from "@/common/enums/wishlistFundEnum";
import { useStore } from "vuex";
import {
  capitalizeEveryWordFirstLetter,
  formatNumberIntoHumanizeForm,
} from "@/utils/staticHelper";
import KpiCardV2 from "@/common/components/KpiCardV2.vue";

export default {
  name: "FundDetail",
  methods: { capitalizeEveryWordFirstLetter, formatNumberIntoHumanizeForm },
  computed: {
    WishlistFundEnum() {
      return WishlistFundEnum;
    },
  },
  components: { KpiCardV2, FundSidebar, FundTable },
  setup() {
    const loading = ref(false);
    const store = useStore();
    const route = useRoute();
    const sumOfRevenues = ref({
      equity_revenues_from_total: 0,
      equity_revenues_from_month: 0,
      acquired_revenues_from_total: 0,
      acquired_revenues_from_month: 0,
      equity_catalogue_value_from_total: 0,
      equity_catalogue_value_from_month: 0,
      acquired_catalogue_value_from_total: 0,
      acquired_catalogue_value_from_month: 0,
    });
    const fundDetail = computed(() => {
      return store.getters["FundsModule/getFundDetail"];
    });
    onMounted(async () => {
      try {
        loading.value = true;
        await store.dispatch(
          "FundsModule/fetchFundDetail",
          route.params.fundID
        );
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    });

    watch(
      () => fundDetail.value,
      (val) => {
        if (val) {
          if (val.tableDataForEquity && val.tableDataForEquity.tracks.length) {
            for (const track of val.tableDataForEquity.tracks) {
              sumOfRevenues.value.equity_revenues_from_total +=
                track.revenueShareFromTotal;
              sumOfRevenues.value.equity_revenues_from_month +=
                track.revenueShareFromMonth;
            }
          }
          if (
            val.tableDataForAcquired &&
            val.tableDataForAcquired.tracks.length
          ) {
            for (const track of val.tableDataForAcquired.tracks) {
              sumOfRevenues.value.acquired_revenues_from_total +=
                track.revenueShareFromTotal;
              sumOfRevenues.value.acquired_revenues_from_month +=
                track.revenueShareFromMonth;
            }
          }
          sumOfRevenues.value.equity_catalogue_value_from_total =
            5 * sumOfRevenues.value.equity_revenues_from_total;

          sumOfRevenues.value.equity_catalogue_value_from_month =
            5 * sumOfRevenues.value.equity_revenues_from_month;

          sumOfRevenues.value.acquired_catalogue_value_from_total =
            5 * sumOfRevenues.value.acquired_revenues_from_total;

          sumOfRevenues.value.acquired_catalogue_value_from_month =
            5 * sumOfRevenues.value.acquired_revenues_from_month;
        }
      }
    );

    return { loading, fundDetail, sumOfRevenues };
  },
};
</script>
<style lang="scss" scoped>
.fund-detail-v2 {
  --bs-gutter-x: 0 !important;
}
</style>
