<template>
  <CardToolbar
    v-loading="loading"
    :title="title"
    :fontStyle="true"
    :padding="false"
    :shadow-class="true"
    margin-class="margin-bottom-12"
    :margin="true"
  >
    <template v-slot:toolbar>
      <a
        class="
          btn btn-sm btn-color-muted btn-active btn-active-primary
          px-4
          active
        "
        data-bs-toggle="modal"
        :data-bs-target="'#update_fund_modal_' + type"
        >Update</a
      >
    </template>
    <template v-slot:body>
      <el-table
        @sort-change="sortResults"
        :data="tableData"
        :fit="true"
        size="medium"
        tooltip-effect="light"
        stripe
        style="width: 100%"
        header-cell-class-name="table-header-text"
        :row-class-name="tableRowClassName"
        :cell-class-name="tableCellClassName"
      >
        <el-table-column
          label="Track"
          prop="track"
          width="350"
          fixed
          align="left"
          sortable="custom"
        >
          <template #default="scope">
            <div
              class="d-flex align-items-center cursor-pointer"
              @click="
                routeToPage(
                  `song/${scope.row.spotifyID}/${scope.row.trackID}`,
                  true
                )
              "
            >
              <el-avatar :size="25" :src="scope.row.imageURL"></el-avatar>
              <span
                class="ms-2 truncate"
                data-tag="span"
                title=""
                data-tooltip="true"
                :data-text="scope.row.track ? scope.row.track : 'N/A'"
              >
                {{ scope.row.track ? scope.row.track : "N/A" }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Artist"
          prop="artists"
          align="left"
          width="180"
          sortable="custom"
        >
          <template #default="scope">
            <p
              class="truncate"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="scope.row.artists ? scope.row.artists : 'N/A'"
            >
              {{ scope.row.artists ? scope.row.artists : "N/A" }}
            </p>
          </template>
        </el-table-column>

        <el-table-column
          label="Total Streams"
          prop="spotifyStreams"
          align="right"
          width="180"
          sortable="custom"
        >
          <template #default="scope">
            {{ formatNumberIntoHumanizeForm(scope.row.spotifyStreams, 0) }}
          </template>
        </el-table-column>

        <el-table-column
          label="Streams of Last Month"
          width="200"
          prop="spotifyStreamsOfLastMonth"
          align="right"
          sortable="custom"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(
                scope.row.spotifyStreamsOfLastMonth,
                0
              )
            }}
          </template>
        </el-table-column>

        <el-table-column
          label="Revenue Share From Total"
          prop="revenueShareFromTotal"
          width="250"
          align="right"
          sortable="custom"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(scope.row.revenueShareFromTotal, 0)
            }}
          </template>
        </el-table-column>

        <el-table-column
          label="Share of Catalogue From Total"
          prop="shareOfStreamProportionTotal"
          width="250"
          align="right"
          sortable="custom"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(
                scope.row.shareOfStreamProportionTotal,
                0
              )
            }}
          </template>
        </el-table-column>

        <el-table-column
          label="Revenue Share From Last Month"
          prop="revenueShareFromMonth"
          width="250"
          align="right"
          sortable="custom"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(scope.row.revenueShareFromMonth, 0)
            }}
          </template>
        </el-table-column>

        <el-table-column
          label="Share of Catalogue From Last Month"
          prop="shareOfStreamProportionMonth"
          width="300"
          align="right"
          sortable="custom"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(
                scope.row.shareOfStreamProportionMonth,
                0
              )
            }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="mt-7 mb-7"
        v-model:currentPage="localPagination.offset"
        v-model:page-size="localPagination.limit"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size="localPagination.limit"
        :small="true"
        layout="total, sizes, prev, pager, next"
        :total="localPagination.total"
        @size-change="paginationChanged"
        @current-change="paginationChanged"
      >
      </el-pagination>
    </template>
  </CardToolbar>
  <UpdateFundModal
    :wishlistID="fund && fund.wishlistID ? fund.wishlistID : ''"
    :wishlist-type="type"
    :id="'update_fund_modal_' + type"
  />
</template>
<script>
import { ref, watch } from "vue";
import {
  formatNumberIntoHumanizeForm,
  routeToPage,
  tableCellClassName,
  tableRowClassName,
} from "@/utils/staticHelper";
import { paginate, sortArray } from "@/aiBetaBench/helpers";
import CardToolbar from "@/common/components/CardToolbar.vue";
import UpdateFundModal from "@/funds/components/UpdateFundModal.vue";

export default {
  name: "FundTable",
  components: { CardToolbar, UpdateFundModal },
  methods: {
    formatNumberIntoHumanizeForm,
    routeToPage,
    tableCellClassName,
    tableRowClassName,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    fund: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const loading = ref(false);
    const tableData = ref([]);
    const tableDataWithoutPagination = ref([]);
    const sortBy = ref(null);
    const sortDir = ref(null);
    const localPagination = ref({
      offset: 1,
      limit: 10,
      total: 0,
      sortBy: "spotifyStreams",
      sortOrder: "desc",
    });
    const sortResults = ({ prop, order }) => {
      sortBy.value = prop;
      sortDir.value = order;
      if (sortBy.value && sortDir.value) {
        tableDataWithoutPagination.value = sortArray(
          sortBy.value,
          sortDir.value,
          JSON.parse(JSON.stringify(tableDataWithoutPagination.value))
        );
      }
      paginationChanged();
    };

    const paginationChanged = () => {
      localPagination.value.total = tableDataWithoutPagination.value.length;
      tableData.value = paginate(
        tableDataWithoutPagination.value,
        localPagination.value.limit,
        localPagination.value.offset
      );
      loading.value = false;
    };
    watch(
      () => props.fund,
      (value) => {
        loading.value = true;
        tableDataWithoutPagination.value = value.tracks ? value.tracks : [];
        paginationChanged();
      },
      { immediate: true }
    );

    return {
      loading,
      tableData,
      localPagination,
      sortResults,
      paginationChanged,
    };
  },
};
</script>
