<template>
  <div class="left-sidebar-v2 me-10">
    <div class="card left-sidebar-card-v2 shadow-card">
      <div class="card-body">
        <div class="card-head d-flex flex-column">
          <span
            class="heading-sideBar truncate text-center"
            data-tag="span"
            title=""
            data-tooltip="true"
            :data-text="
              fundName ? capitalizeEveryWordFirstLetter(fundName) : 'fund name'
            "
            >{{
              fundName ? capitalizeEveryWordFirstLetter(fundName) : "fund name"
            }}</span
          >
          <hr />
        </div>
        <div class="quick-facts ms-2 mb-8">
          <div class="sub-headings-sidebar">
            <div>
              <a href="#">
                <img alt="Logo" src="/favicon.ico" class="h-20px" />
              </a>
              <span> Quick Facts</span>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-8">
              <div class="row">
                <p class="facts-figures">Songs in Equity:</p>
              </div>
              <div class="row">
                <p class="facts-figures">Songs in Acquired:</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <span
                  class="facts-figures fw-700 truncate"
                  data-tag="span"
                  title=""
                  data-tooltip="true"
                  :data-text="
                    formatNumberIntoHumanizeForm(quickFacts.totalTracksInEquity)
                  "
                >
                  {{
                    formatNumberIntoHumanizeForm(quickFacts.totalTracksInEquity)
                  }}
                </span>
              </div>

              <div class="row">
                <span
                  class="facts-figures fw-700 truncate"
                  data-tag="span"
                  title=""
                  data-tooltip="true"
                  :data-text="
                    formatNumberIntoHumanizeForm(
                      quickFacts.totalTracksInAcquired
                    )
                  "
                >
                  {{
                    formatNumberIntoHumanizeForm(
                      quickFacts.totalTracksInAcquired
                    )
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  capitalizeEveryWordFirstLetter,
  formatNumberIntoHumanizeForm,
} from "@/utils/staticHelper";

export default {
  name: "FundSidebar",
  methods: { formatNumberIntoHumanizeForm, capitalizeEveryWordFirstLetter },
  props: {
    fundName: {
      type: String,
      required: true,
    },
    quickFacts: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped>
.left-sidebar-v2 {
  min-width: 255px !important;
}
</style>
